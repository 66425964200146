/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "../node_modules/font-awesome/css/font-awesome.css";
@import "./assets/css/font.css";
@import "~ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.carousel.css";
@import "~ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.theme.default.css";


body {
  background: #fff;
  font-size: 14px;
  color: #000000;
  /* font-family: "Lato-Regular"; */
  font-family: 'Poppins', sans-serif !important;
}
h1, h2, h3, h4, h5, h6, p, input, a, label {
  font-family: 'Poppins', sans-serif !important;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a,
button {
  text-decoration: none;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
}

a:hover {
  text-decoration: none;
}

a:focus {
  outline: 0;
}

p {
  margin-bottom: 0;
}

input:focus,
textarea:focus,
select:focus {
  outline: 0;
  box-shadow: none;
}

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #645d4b;
}

input::-moz-placeholder {
  /* Firefox 19+ */
  color: #645d4b;
}

input:-ms-input-placeholder {
  /* IE 10+ */
  color: #645d4b;
}

input:-moz-placeholder {
  /* Firefox 18- */
  color: #645d4b;
}

/* Text-overflow hidden */

textarea {
  padding: 5px 10px 10px 10px;
}

.promote_content .text-overflow-hidden {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* Menu Icon */

.it-services-hover,
.it-services,
.hand-with-a-dumbbell-hover,
.hand-with-a-dumbbell,
.car-hover,
.car,
.medical-kit-hover,
.medical-kit,
.baby-face-hover,
.baby-face,
.books-stack-of-three-hover,
.books-stack-of-three,
.hotel-hover,
.hotel,
.security-hover,
.security,
.doctor-stethoscope-hover,
.doctor-stethoscope,
.idea-hover,
.idea,
.wine-glasses-hover,
.wine-glasses,
.a-z-categories-hover,
.a-z-categories,
.football-hover,
.football,
.plane-hover,
.plane,
.care-hover,
.care,
.home-hover,
.home,
.lab-flask-hover,
.lab-flask,
.dancer-balance-posture-on-one-leg-hover,
.dancer-balance,
.scissors-and-comb-hover,
.scissors-and-comb {
  display: inline-block;
  background: url("./assets/images/menu-icon.png") no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  text-align: left;
}

.it-services-hover {
  background-position: -10px -0px;
  width: 28px;
  height: 20px;
}

.it-services {
  background-position: -10px -30px;
  width: 28px;
  height: 20px;
}

.hand-with-a-dumbbell-hover {
  background-position: -10px -60px;
  width: 25px;
  height: 20px;
}

.hand-with-a-dumbbell {
  background-position: -10px -90px;
  width: 25px;
  height: 20px;
}

.car-hover {
  background-position: -10px -120px;
  width: 24px;
  height: 17px;
}

.car {
  background-position: -10px -147px;
  width: 24px;
  height: 17px;
}

.medical-kit-hover {
  background-position: -10px -174px;
  width: 23px;
  height: 20px;
}

.medical-kit {
  background-position: -10px -204px;
  width: 23px;
  height: 20px;
}

.baby-face-hover {
  background-position: -10px -234px;
  width: 22px;
  height: 22px;
}

.baby-face {
  background-position: -10px -266px;
  width: 22px;
  height: 22px;
}

.books-stack-of-three-hover {
  background-position: -10px -298px;
  width: 22px;
  height: 18px;
}

.books-stack-of-three {
  background-position: -10px -326px;
  width: 22px;
  height: 18px;
}

.hotel-hover {
  background-position: -10px -354px;
  width: 22px;
  height: 21px;
}

.hotel {
  background-position: -10px -385px;
  width: 22px;
  height: 21px;
}

.security-hover {
  background-position: -10px -416px;
  width: 22px;
  height: 21px;
}

.security {
  background-position: -10px -447px;
  width: 22px;
  height: 21px;
}

.doctor-stethoscope-hover {
  background-position: -10px -478px;
  width: 21px;
  height: 26px;
}

.doctor-stethoscope {
  background-position: -10px -514px;
  width: 21px;
  height: 26px;
}

.idea-hover {
  background-position: -10px -550px;
  width: 21px;
  height: 21px;
}

.idea {
  background-position: -10px -581px;
  width: 21px;
  height: 21px;
}

.wine-glasses-hover {
  background-position: -10px -612px;
  width: 21px;
  height: 23px;
}

.wine-glasses {
  background-position: -10px -645px;
  width: 21px;
  height: 23px;
}

.a-z-categories-hover {
  background-position: -10px -678px;
  width: 20px;
  height: 14px;
}

.a-z-categories {
  background-position: -10px -702px;
  width: 20px;
  height: 14px;
}

.football-hover {
  background-position: -10px -726px;
  width: 20px;
  height: 18px;
}

.football {
  background-position: -10px -754px;
  width: 20px;
  height: 18px;
}

.plane-hover {
  background-position: -10px -782px;
  width: 18px;
  height: 18px;
}

.plane {
  background-position: -10px -810px;
  width: 18px;
  height: 18px;
}

.care-hover {
  background-position: -10px -838px;
  width: 17px;
  height: 19px;
}

.care {
  background-position: -10px -867px;
  width: 17px;
  height: 19px;
}

.home-hover {
  background-position: -10px -896px;
  width: 16px;
  height: 19px;
}

.home {
  background-position: -10px -925px;
  width: 16px;
  height: 19px;
}

.lab-flask-hover {
  background-position: -10px -954px;
  width: 16px;
  height: 22px;
}

.lab-flask {
  background-position: -10px -986px;
  width: 16px;
  height: 22px;
}

.dancer-balance-posture-on-one-leg-hover {
  background-position: -10px -1018px;
  width: 15px;
  height: 19px;
}

.dancer-balance {
  background-position: -10px -1047px;
  width: 15px;
  height: 19px;
}

.scissors-and-comb-hover {
  background-position: -10px -1076px;
  width: 15px;
  height: 20px;
}

.scissors-and-comb {
  background-position: -10px -1106px;
  width: 15px;
  height: 20px;
}

/* Menu Icon End */

.help-block {
  color: red;
}
.bd_btn {
  background: var(--theme-bd-based-text-primary-color);
  border: 1px solid var(--theme-bd-based-text-primary-color);
  color: #f1f0f0;
  font-size: 20px;
  font-family: "Lato-Regular";
  border-radius: 100px;
  cursor: pointer;
  text-transform: uppercase;
  padding: 5px 17px;
}
.bd_btn:hover {
  background: transparent;
  color: var(--theme-bd-based-secondary-color);
}
.mrc_btn {
  background: var(--theme-mrc-based-primary-color);
  border: 1px solid var(--theme-mrc-based-primary-color);
  color: #ffffff;
  font-size: 20px;
  font-family: "Lato-Regular";
  border-radius: 100px;
  cursor: pointer;
  text-transform: uppercase;
  padding: 5px 17px;
}

.ryec_btn {
  background: var(--theme-ryc-based-secondary-color);
  border: 1px solid var(--theme-ryc-based-secondary-color);
  color: #ffffff;
  font-size: 16px;
  font-family: "Poppins";
  border-radius: 100px;
  cursor: pointer;
  text-transform: uppercase;
  padding: 5px 17px;
}

.ryec_btn:focus {
  outline: 0;
}

.mrc_btn:focus {
  outline: 0;
}

.ryec_btn:hover {
  background: transparent;
  color: var(--theme-ryc-based-secondary-color);
}
.mrc_btn:hover {
  background: transparent;
  color: var(--theme-mrc-based-primary-color);
}
.bd_btn:hover {
  background: transparent;
  color: var(--theme-bd-based-primary-color);
}

.ryec_btn.disabled,
.ryec_btn:disabled {
  background-color: var(--theme-ryc-based-secondary-color);
  cursor: not-allowed;
  color: #fff;
}
.mrc_btn.disabled,
.mrc_btn:disabled {
  background-color: var(--theme-mrc-based-primary-color);
  cursor: not-allowed;
  color: #fff;
}
/* Left social view css */

.social-media {
  position: fixed;
  top: 200px;
  right: 10px;
  list-style-type: none;
  padding: 0;
  z-index: 99;
}

.social-media li {
  height: 40px;
  width: 40px;
  margin-bottom: 10px;
}

.social-media li a {
  height: 100%;
  width: 100%;
  background: #a77e36;
  color: #fff;
  line-height: 40px;
  font-size: 18px;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
}

.social-media li a:hover {
  background: #d4a552;
}

.success_message {
  text-align: center;
  background: #f7ca62;
  padding: 10px 0;
  font-size: 16px;
}

.control-group input.alert_mess {
  border-bottom: 1px solid red !important;
}

/* nav_bar */

.nav_bar_MRC {
  position: absolute;
  left: 15px;
  top: 42px;
  min-height: 250px;
  background: rgba(34, 34, 34, 0.9);
  width: 280px;
  z-index: 999;
  padding: 15px 0;
  /*display: none;*/
}

.nav_bar_MRC li a {
  font-size: 20px;
  color: #ffffff;
  line-height: 35px;
  text-transform: capitalize;
}

.nav_bar_RYC {
  position: absolute;
  left: 15px;
  top: 42px;
  min-height: 250px;
  background: rgba(34, 34, 34, 0.9);
  width: 280px;
  z-index: 999;
  padding: 15px 0;
  /*display: none;*/
}
.nav_bar_bd {
  position: absolute;
  left: 15px;
  top: 42px;
  min-height: 250px;
  background: rgba(34, 34, 34, 0.9);
  width: 280px;
  z-index: 999;
  padding: 15px 0;
  /*display: none;*/
}

.nav_bar_RYC li a {
  font-size: 20px;
  color: #ffffff;
  line-height: 35px;
  text-transform: capitalize;
}
.nav_bar_bd li a {
  font-size: 20px;
  color: #ffffff;
  line-height: 35px;
  text-transform: capitalize;
}
.nav_bar_bd {
  position: absolute;
  left: 15px;
  top: 42px;
  min-height: 250px;
  background: rgba(34, 34, 34, 0.9);
  width: 280px;
  z-index: 999;
  padding: 15px 0;
  /*display: none;*/
}

.nav_bar_bd li a {
  font-size: 20px;
  color: #ffffff;
  line-height: 35px;
  text-transform: capitalize;
}

.useful_links_RYC {
  margin-top: 30px;
}

.useful_links_RYC h5 {
  text-transform: uppercase;
  color: var(--theme-ryc-based-secondary-color);;
  margin: 0 10px;
  padding: 10px 0 5px 0;
  border-top: 1px solid #f3f3f3;
  font-family: "Lato-Bold";
}
.useful_links_MRC {
  margin-top: 30px;
}

.useful_links_MRC h5 {
  text-transform: uppercase;
  color: var(--theme-mrc-based-primary-color);;
  margin: 0 10px;
  padding: 10px 0 5px 0;
  border-top: 1px solid #f3f3f3;
  font-family: "Lato-Bold";
}

.nav_bar_MRC li {
  padding: 0 15px;
  cursor: pointer;
  margin-bottom: 5px;
  transition: all 0.3s;
}

.nav_bar_MRC li:hover,
.nav_bar_MRC li.active {
  background: var(--theme-mrc-based-primary-color);
}

.nav_bar_bd li:hover,
.nav_bar_bd li.active {
  background: var(--theme-mrc-based-primary-color);
}
.nav_bar_RYC li {
  padding: 0 15px;
  cursor: pointer;
  margin-bottom: 5px;
  transition: all 0.3s;
}
.nav_bar_bd li {
  padding: 0 15px;
  cursor: pointer;
  margin-bottom: 5px;
  transition: all 0.3s;
}

.nav_bar_RYC li:hover,
.nav_bar_RYC li.active {
  background: var(--theme-ryc-based-secondary-color);
}

.nav_bar_bd li:hover,
.nav_bar_bd  li.active {
  background: var(--theme-bd-based-primary-color);
}
.nav_bar_MRC:before {
  bottom: 100%;
  left: 05%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(34, 34, 34, 0);
  border-bottom-color: #232222;
  border-width: 7px;
  margin-left: -7px;
}
.nav_bar_bd:before {
  bottom: 100%;
  left: 05%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(34, 34, 34, 0);
  border-bottom-color: #232222;
  border-width: 7px;
  margin-left: -7px;
}

.nav_bar_RYC:before {
  bottom: 100%;
  left: 05%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(34, 34, 34, 0);
  border-bottom-color: #232222;
  border-width: 7px;
  margin-left: -7px;
}

/* nav_bar */

/* menu css start */

.menu_RYEC {
  background: #ffffff;
  position: absolute;
  left: 15px;
  top: 50px;
  height: 550px;
  width: 240px;
  z-index: 1;
  padding: 15px 15px 0 15px;
  -webkit-box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  display: none;
}

.menu_RYEC h2 {
  font-size: 22px;
}

.menu_RYEC ul li span {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

.menu_RYEC ul li {
  border-bottom: 1px solid #eaeaea;
}

.menu_RYEC ul li:last-child {
  border-bottom: 0;
  background: #363636;
  margin: 0 -15px;
  padding: 0 15px;
}

.menu_RYEC ul li:last-child a {
  color: #ffffff;
}

.menu_RYEC ul li a {
  display: block;
  color: #222222;
  font-size: 15px;
  padding: 10px 0;
}

.menu_RYEC ul li a:hover {
  color: var(--theme-mrc-based-primary-color);
}

/* Menu css End  */

/* Flex css */

.flex-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/*.flex-container:after, .flex-container:before {
    display: block;
}*/

.flex-container .flex-items {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -moz-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: stretch;
  margin-bottom: 30px;
}

.mrc_pagination_view ngb-pagination ul li .page-link {
  border: 1px solid #b6b8b8;
  color: #050000 !important;
  padding: 10px 20px;
  font-family: "Lato-Bold";
  font-size: 18px;
}

.mrc_pagination_view .page-item.disabled .page-link {
  border: 1px solid #b6b8b8;
}

.mrc_pagination_view .page-item.active .page-link {
  color: #fff !important;
  background-color: var(--theme-mrc-based-primary-color);
  border-color: var(--theme-mrc-based-primary-color);
}

.orange .mrc_pagination_view .page-item.active .page-link {
  background: rgb(255, 137, 35);
  background: linear-gradient(
    90deg,
    rgba(255, 137, 35, 1) 80%,
    rgba(255, 169, 39, 1) 100%
  );
}

.blue .mrc_pagination_view .page-item.active .page-link {
  background-color: #4760ff;
}

.green .mrc_pagination_view .page-item.active .page-link {
  background-color: #17c788;
}

.mrc_pagination_view .page-link:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(199, 155, 78, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(199, 155, 78, 0.25);
}

.mrc_pagination_view .page-link:hover {
  color: #ffffff !important;
  text-decoration: none;
  background-color: var(--theme-mrc-based-primary-color);
  border-color: #fa8b3f !important;
}

.orange .mrc_pagination_view .page-link:hover {
  background: rgb(255, 137, 35);
  background: linear-gradient(
    90deg,
    rgba(255, 137, 35, 1) 80%,
    rgba(255, 169, 39, 1) 100%
  );
  border-color: rgb(255, 137, 35) !important;
}

.blue .mrc_pagination_view .page-link:hover {
  background-color: #4760ff;
  border-color: #4760ff !important;
}

.green .mrc_pagination_view .page-link:hover {
  background-color: #17c788;
  border-color: #17c788 !important;
}

/* ryc pagination */
.ryc_pagination_view ngb-pagination ul li .page-link {
  border: 1px solid #b6b8b8;
  color: #050000 !important;
  padding: 10px 20px;
  font-family: "Lato-Bold";
  font-size: 18px;
}

.ryc_pagination_view .page-item.disabled .page-link {
  border: 1px solid #b6b8b8;
}

.ryc_pagination_view .page-item.active .page-link {
  color: #fff !important;
  background-color: var(--theme-ryc-based-secondary-color);
  border-color: var(--theme-ryc-based-secondary-color);
}



.orange .ryc_pagination_view .page-item.active .page-link {
  background: rgb(255, 137, 35);
  background: linear-gradient(
    90deg,
    rgba(255, 137, 35, 1) 80%,
    rgba(255, 169, 39, 1) 100%
  );
}

.blue .ryc_pagination_view .page-item.active .page-link {
  background-color: #4760ff;
}

.green .ryc_pagination_view .page-item.active .page-link {
  background-color: #17c788;
}

.ryc_pagination_view .page-link:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(199, 155, 78, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(199, 155, 78, 0.25);
}

.ryc_pagination_view .page-link:hover {
  color: #ffffff !important;
  text-decoration: none;
  background-color: var(--theme-ryc-based-secondary-color);
  border-color: var(--theme-ryc-based-secondary-color) !important;
}

.orange .ryc_pagination_view .page-link:hover {
  background: rgb(255, 137, 35);
  background: linear-gradient(
    90deg,
    rgba(255, 137, 35, 1) 80%,
    rgba(255, 169, 39, 1) 100%
  );
  border-color: rgb(255, 137, 35) !important;
}

.blue .ryc_pagination_view .page-link:hover {
  background-color: #4760ff;
  border-color: #4760ff !important;
}

.green .ryc_pagination_view .page-link:hover {
  background-color: #17c788;
  border-color: #17c788 !important;
}





/* bd pagination */
.bd_pagination_view ngb-pagination ul li .page-link {
  border: 1px solid #b6b8b8;
  color: #050000 !important;
  padding: 10px 20px;
  font-family: "Lato-Bold";
  font-size: 18px;
}

.bd_pagination_view .page-item.disabled .page-link {
  border: 1px solid #b6b8b8;
}

.bd_pagination_view .page-item.active .page-link {
  color: #fff !important;
  background-color: var(--theme-ryc-based-secondary-color);
  border-color: var(--theme-ryc-based-secondary-color);
}



.orange .bd_pagination_view .page-item.active .page-link {
  background: rgb(255, 137, 35);
  background: linear-gradient(
    90deg,
    rgba(255, 137, 35, 1) 80%,
    rgba(255, 169, 39, 1) 100%
  );
}

.blue .bd_pagination_view .page-item.active .page-link {
  background-color: #4760ff;
}

.green .bd_pagination_view .page-item.active .page-link {
  background-color: #17c788;
}

.bd_pagination_view .page-link:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(199, 155, 78, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(199, 155, 78, 0.25);
}

.bd_pagination_view .page-link:hover {
  color: #ffffff !important;
  text-decoration: none;
  background-color: var(--theme-ryc-based-secondary-color);
  border-color: var(--theme-ryc-based-secondary-color) !important;
}

.orange .bd_pagination_view .page-link:hover {
  background: rgb(255, 137, 35);
  background: linear-gradient(
    90deg,
    rgba(255, 137, 35, 1) 80%,
    rgba(255, 169, 39, 1) 100%
  );
  border-color: rgb(255, 137, 35) !important;
}

.blue .bd_pagination_view .page-link:hover {
  background-color: #4760ff;
  border-color: #4760ff !important;
}

.green .bd_pagination_view .page-link:hover {
  background-color: #17c788;
  border-color: #17c788 !important;
}







.footer_section {
  margin-top: 50px;
}

.ng2-carouselamos-wrapper {
  width: 90% !important;
}

.our_products .ng2-carouselamos-wrapper {
  width: 100% !important;
}
.our_products_slider .controls {
  top: 59% !important;
}
.our_products_slider.online-store_slider .controls {
  top: 76% !important;
}
.our_products_slider .controls button {
  background: white !important;
  border: 0;
  border-radius: 50%;
  box-shadow: 2px 2px 4px #000;
  padding: 0 9px;
}
.our_products_slider .controls button i {
  font-size: 24px;
  color: var(--theme-mrc-based-primary-color);
}

.orange .our_products_slider .controls button i {
  color: #ff6b03;
}

.blue .our_products_slider .controls button i {
  color: #4760ff;
}

.green .our_products_slider .controls button i {
  color: #17c788;
}

.our_products_slider .controls button {
  outline: none;
  cursor: pointer;
}

.ratings_reviews .progress_bar_cst_mrc .progress, .ratings_reviews .progress_bar_cst_ryc .progress{
  margin-bottom: 15px;
  height: 8px;
  background: #fff;
}
.business_detail_content .ratings_reviews .progress_bar_cst_mrc .progress, .business_detail_content .ratings_reviews .progress_bar_cst_ryc .progress {
  background: #d3d3d3;
}
.ratings_reviews .progress_bar_cst_mrc .progress-bar {
  background: var(--theme-mrc-based-primary-color);
}
.ratings_reviews .progress_bar_cst_ryc .progress-bar {
  background: var(--theme-ryc-based-secondary-color);
}

.orange .ratings_reviews .progress_bar_cst_mrc .progress-bar, .orange .ratings_reviews .progress_bar_cst_ryc .progress-bar {
  background: rgb(255, 137, 35);
  background: linear-gradient(
    90deg,
    rgba(255, 137, 35, 1) 80%,
    rgba(255, 169, 39, 1) 100%
  );
}

.blue .ratings_reviews .progress_bar_cst_mrc .progress-bar, .blue .ratings_reviews .progress_bar_cst_ryc .progress-bar {
  background-color: #4760ff;
}

.green .ratings_reviews .progress_bar_cst_mrc .progress-bar, .green .ratings_reviews .progress_bar_cst_ryc .progress-bar {
  background-color: #17c788;
}

.swal2-popup {
  width: 280px !important;
  height: 300px;
}

.swal2-close {
 padding-left: 25px;
}

.swal2-title {

  font-size: 18px !important;
}

.progress_bar_cst_mrc .progress, .progress_bar_cst_ryc .progress {
  margin-bottom: 15px;
  height: 8px;
  background: #fff;
}

.progress_bar_cst_mrc .progress-bar {
  background: var(--theme-mrc-based-primary-color);
}
.progress_bar_cst_ryc .progress-bar {
  background: var(--theme-ryc-based-secondary-color);
}
.our_products .online-store_slider .ng2-carouselamos-wrapper {
  width: 100% !important;
}

/*image gallery start*/

.image_gallery .modal-content,
.product_gallery .modal-content,
.service_gallery .modal-content {
  background: transparent;
  border: none;
}

.image_gallery .modal-header,
.product_gallery .modal-header,
.service_gallery .modal-header {
  border: none;
  padding: 20px 20px 5px 0;
}

.image_gallery .close,
.product_gallery .close,
.service_gallery .close,
.video_gallery .close {
  color: #fff;
  opacity: 1;
  outline: none;
}

.image_gallery .carousel-control-prev,
.carousel-control-next {
  opacity: 1;
}

.image_gallery .carousel {
  outline: none;
}

.image_gallery .carousel-control-prev-icon {
  cursor: pointer;
}

.image_gallery .carousel-control-next-icon {
  cursor: pointer;
}

.image_gallery .carousel-indicators {
  display: none;
}

.image_gallery .modal-body,
.product_gallery .modal-body,
.service_gallery .modal-body {
  padding-top: 2px;
}

.image_gallery .carousel-item img {
  height: 100% !important;
}

.image_gallery .modal-dialog,
.product_gallery .modal-dialog,
.service_gallery .modal-dialog {
  margin: 0 auto;
}

/*image gallery end*/

.product_gallery .modal-body,
.service_gallery .modal-body {
  background: #fff;
  padding: 0;
}

.product_gallery .description h4,
.service_gallery .description h4 {
  position: relative;
  text-align: center;
  text-transform: capitalize;
  padding-top: 20px;
  font-family: "Lato-Heavy";
}

.product_gallery .description span,
.service_gallery .description span {
  text-align: right;
  display: block;
  font-size: 20px;
  color: var(--theme-mrc-based-primary-color);
  padding-top: 20px;
}

.product_gallery .description span i,
.service_gallery .description i {
  padding-right: 10px;
}

.product_gallery ngb-carousel,
.service_gallery ngb-carousel {
  outline: none;
}

.product_gallery .description,
.service_gallery .description {
  padding: 0 40px;
  padding-bottom: 30px;
  text-align: center;
}

.product_gallery .description p,
.service_gallery .description p {
  padding: 5px 0;
  font-size: 18px;
  line-height: 25px;
  text-align: justify;
}

.product_gallery .carousel-indicators {
  display: none;
}

.product_gallery .description .content,
.service_gallery .description .content {
  margin-top: 20px;
}

.product_gallery .img_outer img,
.service_gallery .img_outer img {
  height: 100% !important;
}

.product_gallery .description h4::after,
.service_gallery .description h4::after {
  position: absolute;
  content: "";
  width: 65%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 2px;
  bottom: -10px;
  background: -moz-linear-gradient(
    left,
    rgba(197, 155, 66, 0) 0%,
    rgba(197, 155, 66, 0.8) 15%,
    rgba(197, 155, 66, 1) 19%,
    rgba(197, 155, 66, 1) 20%,
    rgba(197, 155, 66, 1) 51%,
    rgba(197, 155, 66, 1) 81%,
    rgba(197, 155, 66, 0.8) 85%,
    rgba(197, 155, 66, 0) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(197, 155, 66, 0) 0%,
    rgba(197, 155, 66, 0.8) 15%,
    rgba(197, 155, 66, 1) 19%,
    rgba(197, 155, 66, 1) 20%,
    rgba(197, 155, 66, 1) 51%,
    rgba(197, 155, 66, 1) 81%,
    rgba(197, 155, 66, 0.8) 85%,
    rgba(197, 155, 66, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(197, 155, 66, 0) 0%,
    rgba(197, 155, 66, 0.8) 15%,
    rgba(197, 155, 66, 1) 19%,
    rgba(197, 155, 66, 1) 20%,
    rgba(197, 155, 66, 1) 51%,
    rgba(197, 155, 66, 1) 81%,
    rgba(197, 155, 66, 0.8) 85%,
    rgba(197, 155, 66, 0) 100%
  );
}

.product_gallery .carousel-control-prev,
.product_gallery .carousel-control-next {
  opacity: 1;
}

.product_gallery .carousel-control-prev-icon,
.product_gallery .carousel-control-next-icon {
  cursor: pointer;
}

.enquiry_popup h5 {
  font-family: "Lato-Heavy";
}

.enquiry_popup .modal-header {
  border: none;
  text-transform: uppercase;
}

.enquiry_popup .modal-body input,
.enquiry_popup .modal-body textarea,
.rating_popup .modal-body input,
.rating_popup .modal-body textarea {
  width: 100%;
  font-size: 20px;
  /* border-color: #000; */
}

.enquiry_popup .modal-body .business-content-mrc {
  margin: 0px 20px 20px;
  text-align: center;
  color: var(--theme-mrc-based-primary-color);
}

.enquiry_popup .modal-body .business-content-ryc {
  margin: 0px 20px 20px;
  text-align: center;
  color: var(--theme-ryc-based-secondary-color);
}

.enquiry_popup .modal-body .control-group .controls,
.rating_popup .modal-body .control-group .controls {
  margin-bottom: 30px;
}

.enquiry_popup .modal-body #title {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #000;
}

.enquiry_popup .modal-body input::-webkit-input-placeholder,
.enquiry_popup .modal-body textarea::-webkit-input-placeholder {
  color: #767373;
}

.enquiry_popup .modal-body input::-moz-placeholder,
.enquiry_popup .modal-body textarea::-moz-placeholder {
  color: #767373;
}

.enquiry_popup .modal-body input:-ms-input-placeholder,
.enquiry_popup .modal-body textarea:-ms-input-placeholder {
  color: #767373;
}

.enquiry_popup .modal-body input:-moz-placeholder,
.enquiry_popup .modal-body textarea:-ms-input-placeholder {
  color: #767373;
}

.enquiry_popup .modal-body .profile_btn,
.rating_popup .modal-body .profile_btn {
  text-align: center;
}

.enquiry_popup .modal-body .profile_btn button,
.rating_popup .modal-body .profile_btn button {
  width: 50%;
}

.enquiry_popup.blue .modal-body .profile_btn button,
.rating_popup.blue .modal-body .profile_btn button {
  background: #4760ff !important;
  border: 1px solid #4760ff;
}

.enquiry_popup.orange .modal-body .profile_btn button,
.rating_popup.orange .modal-body .profile_btn button {
  background: #ff8923 !important;
  border: 1px solid #ff8923;
}

.enquiry_popup.green .modal-body .profile_btn button,
.rating_popup.green .modal-body .profile_btn button {
  background: #17c788 !important;
  border: 1px solid #17c788;
}

.rating_popup .modal-body textarea::-webkit-input-placeholder {
  color: #767373;
}

.rating_popup .modal-body textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #767373;
}

.rating_popup .modal-body textarea:-ms-input-placeholder {
  color: #767373;
}

.rating_popup .modal-body textarea:-moz-placeholder {
  color: #767373;
}

.rating_popup .modal-body .rate {
  font-size: 20px;
  color: #000;
  display: inline-block;
  font-family: "Lato-Bold";
  vertical-align: middle;
  margin-right: 20px;
}

.rating_popup .modal-body .rate_bar {
  display: inline-block;
  margin-bottom: 0;
}
.rating_popup .modal-body .rate_bar .d-inline-flex span {
  font-size: 25px;
}

.desc_popup h5,
.allcategory_popup h5 {
  color: var(--theme-mrc-based-primary-color);
  font-family: "Lato-Bold";
}

.desc_popup .modal-dialog {
  max-width: 600px;
}

.desc_popup .modal-body p {
  text-align: justify;
  word-wrap: break-word;
}

/* .mobile_popup .modal-body input{padding:5px !important;} */

.mobile_popup .modal-body .profile_btn {
  text-align: center;
}

.mobile_popup .modal-content {
  background: transparent;
  border: none;
}

.mobile_popup .modal-body {
  background: #fff;
}

.mobile_popup .modal-header {
  background: #fff;
}

.mobile_popup .modal-body .profile_btn button {
  width: 50%;
  padding: 10px 0px;
}

.mobile_popup .modal-body .profile_btn p {
  font-size: 14px;
  margin-top: 20px;
}

.mobile_popup .modal-body .profile_btn p > a {
  color: var(--theme-mrc-based-primary-color);
  text-decoration: underline;
  font-family: "Lato-Bold";
}

.mobile_popup .modal-body .member_name {
  font-size: 18px;
  text-transform: capitalize;
  text-align: center;
  font-family: "Lato-Bold";
}

.mobile_popup .modal-body .user_img {
  height: 100px;
  width: 100px;
  overflow: hidden;
  margin: 0 auto;
}

.mobile_popup .modal-body .user_img img {
  height: 100%;
  width: 100%;
  border-radius: 100%;
}

.mobile_popup .modal-body .result_text {
  font-size: 20px;
  text-align: center;
  color: var(--theme-mrc-based-primary-color);
}
.modal.pending_request-modal{
  overflow: hidden;
}
.pending_request-modal .modal-content{
  max-height: calc(100vh - 50px);
  overflow-y: auto;
}
.allcategory_popup ul li {
  font-size: 18px;
  padding: 5px 0;
}
.allcategory_popup ul li .main-cate {
  font-weight: bold;
}

.allcategory_popup .modal-body {
  height: 400px;
  overflow-y: scroll;
}

.allcategory_popup ul li:last-child {
  border-bottom: none;
}

.allcategory_popup .close {
  outline: none;
}

.opne_time_listing .mat-checkbox-checked.mat-accent .mat-checkbox-background,
.opne_time_listing
  .mat-checkbox-indeterminate.mat-accent
  .mat-checkbox-background {
  background-color: var(--theme-mrc-based-primary-color);
}

.opne_time_listing .mat-checkbox-inner-container {
  margin-right: 15px;
}

.opne_time_listing .mat-form-field-wrapper {
  padding-bottom: 1.15em;
}

.opne_time_listing .mat-form-field-underline {
  background-color: #ddd8d8;
}

.opne_time_listing .mat-form-field-hide-placeholder .mat-select-placeholder {
  color: #000000;
}

.share_popup .modal-body .social_profile ul {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.share_popup .modal-body .social_profile ul li {
  margin: 0px 10px;
}

.share_popup .modal-body .social_profile {
  margin-top: 20px;
}

.share_popup .modal-header h5 {
  /* color: var(--theme-mrc-based-primary-color); */
}

.share_popup .close {
  outline: none;
}

.edit_category_popup .category_dropdown {
  display: inline-block;
  vertical-align: middle;
  width: 60%;
  position: relative;
  margin-right: 10px;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.edit_category_popup .category_dropdown::before {
  position: absolute;
  right: 12px;
  top: 7px;
  font-size: 25px;
  color: var(--theme-mrc-based-primary-color);;
  content: "\F0D7";
  z-index: 1;
  pointer-events: none;
}

.edit_category_popup .category_dropdown select {
  width: 100%;
  padding: 0px 25px 0px 10px;
  height: 40px;
  font-size: 15px;
  border: 1px solid #000;
  vertical-align: middle;
  font-family: "Lato-Medium";
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  text-transform: capitalize;
}

.edit_category_popup .close {
  outline: none;
}

.edit_category_popup .modal-body h5 {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.edit_category_popup h5 {
  color: var(--theme-mrc-based-primary-color);
  font-family: "Lato-bold";
  text-transform: capitalize;
}

.edit_category_popup .category_outer {
  margin-bottom: 20px;
}

.edit_category_popup .modal-dialog {
  max-width: 750px;
}

.edit_category_popup .cat_breadcrumb mat-chip {
  background: none;
  font-family: "Lato-bold";
  padding: 0;
  font-size: 14px;
}

.edit_category_popup .category_list {
  box-shadow: 1px 1px 10px #aba8a8;
  padding: 15px 5px;
  margin: 20px 0;
  height: 450px;
  overflow-y: auto;
}

.edit_category_popup .category_list ul li {
  padding: 20px 5px;
  font-size: 18px;
  border-bottom: 1px solid #eae8e8;
  cursor: pointer;
  text-transform: capitalize;
}

.edit_category_popup .category_list ul li:last-child {
  border-bottom: none;
}

.edit_category_popup .category_list ul li:hover {
  background: #f1f0f0;
}

.edit_category_popup .category_list ul li i {
  float: right;
  font-size: 20px;
}

.edit_category_popup .modal-body {
  padding: 20px 50px;
}

.edit_category_popup .category_list ul li button {
  padding: 0px 15px;
  float: right;
  font-size: 18px;
}

.edit_category_popup .category_selected mat-chip {
  font-family: "Lato-bold";
  font-size: 14px;
  color: #646464 !important;
  border-radius: 0 !important;
  padding: 10px 30px 10px 15px;
  position: relative;
  margin-bottom: 8px !important;
  margin-right: 8px !important;
  margin-left: 0 !important;
}

.edit_category_popup .category_selected {
  box-shadow: 1px 1px 10px #aba8a8;
  padding: 10px;
}

.edit_category_popup .category_selected mat-chip i {
  position: absolute;
  right: 7px;
  top: 10px;
  cursor: pointer;
}

.mat-form-field-ripple {
  background-color: var(--theme-mrc-based-primary-color);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow,
.mat-focused .mat-form-field-label {
  color: var(--theme-mrc-based-primary-color);
}

.edit_business_form select.form-control {
  border: none !important;
  border-bottom: 2px solid #ddd8d8 !important;
  border-radius: 0 !important;
  padding: 3px;
}

.edit_business_form select:focus {
  outline: none !important;
  box-shadow: none !important;
}

.filter_popup .modal-header h5 {
  color: var(--theme-mrc-based-primary-color);
  font-family: "Lato-bold";
  text-transform: capitalize;
  font-size: 30px;
}

.filter_popup .modal-header {
  border-bottom: 0;
}

.filter_popup .modal-dialog {
  max-width: 600px;
}

.filter_popup .modal-body {
  padding: 10px 10%;
  padding-bottom: 0px;
}

.filter_popup .close {
  outline: none;
}

/*range slider start*/

.rangeslider_cst .irs-with-grid .irs-grid {
  top: 0;
  display: none;
}

.rangeslider_cst .irs-grid-pol.small {
  height: 8px;
}

.rangeslider_cst .irs-grid-pol {
  background: #c2c2c2;
}

.rangeslider_cst .irs-min {
  display: none;
}

.rangeslider_cst .irs-max {
  display: none;
}

.rangeslider_cst .irs-line {
  height: 4px;
}

.rangeslider_cst .irs-bar {
  height: 4px;
}

.rangeslider_cst .irs-line-left {
  background: #c2c2c2;
}

.rangeslider_cst .irs-line-mid {
  background: #c2c2c2;
}

.rangeslider_cst .irs-line-right {
  background: #c2c2c2;
}

.rangeslider_cst .irs-bar {
  background: var(--theme-mrc-based-primary-color);
}

.rangeslider_cst .irs-slider.to,
.rangeslider_cst .irs-slider.from {
  width: 25px !important;
  height: 25px;
  background: #fff;
  top: 13px;
  border-radius: 100%;
  border: 2px solid var(--theme-mrc-based-primary-color);
  box-shadow: 0px 0px 7px #aba9a9;
  cursor: pointer;
}

.rangeslider_cst .irs-grid-text {
  display: none;
}

.rangeslider_cst .irs-from,
.rangeslider_cst .irs-to,
.rangeslider_cst .irs-single {
  display: none;
}

/*range slider end*/

/* .filter_popup .price_title {font-size: 0;}
.filter_popup .price_title span{font-size: 18px;color: #a1a0a0;}
.filter_popup .price_title  h6{font-size: 24px;color: #000;}
.filter_popup .price_left{display: inline-block;text-align: center;}
.filter_popup .price_right{display: inline-block;float:right;text-align: center;} */

.filter_popup .apply_btn,
.market_popup .apply_btn {
  text-align: center;
  width: 100%;
}

.filter_popup .apply_btn .ryec_btn,
.market_popup .apply_btn .ryec_btn {
  padding: 15px 20%;
  font-family: "Lato-bold";
}

.filter_popup .category_select {
  display: block;
  position: relative;
  margin-bottom: 15px;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 20px 0;
}

.filter_popup .category_select select {
  width: 100%;
  padding: 5px 0px;
  /* height: 140px; */
  font-size: 16px;
  line-height: 26px;
  border: 0;
  cursor: pointer;
  position: relative;
  font-family: "Lato-semibold";
  border-bottom: 2px solid #ddd8d8;
  text-transform: capitalize;
}

.category_select .ngx-dropdown-container .ngx-dropdown-button {
  width: 100%;
  padding: 5px 0px !important;
  /* height: 140px; */
  font-size: 20px !important;
  line-height: 26px !important;
  border: 0 !important;
  cursor: pointer;
  position: relative;
  font-family: "Lato-regular" !important;
  border-bottom: 2px solid #ddd8d8 !important;
  text-transform: capitalize !important;
  border-radius: 0 !important;
  color: #414141 !important;
}

.category_select .ngx-dropdown-container .ngx-dropdown-button:focus {
  outline: none;
}

.category_select .ngx-dropdown-container .ngx-dropdown-list-container {
  font-size: 16px;
  font-family: "Lato-semibold";
  line-height: 26px;
  padding: 3px 9px !important;
}

.category_select .ngx-dropdown-container .ngx-dropdown-list-container ul li {
  padding: 3px 9px !important;
}

.category_select
  .ngx-dropdown-container
  .ngx-dropdown-list-container
  ul.selected-items
  li {
  background-color: #fff !important;
  color: #2d2d2c !important;
  margin-bottom: 2px !important;
}

.category_select
  .ngx-dropdown-container
  .ngx-dropdown-list-container
  ul
  li:hover {
  color: #fa8b3f !important;
}

.category_select
  .ngx-dropdown-container
  .ngx-dropdown-list-container
  .search-container {
  position: fixed;
  padding-top: 10px;
  margin-top: 5px;
  /* width: 74%; */
}

.category_select
  .ngx-dropdown-container
  .ngx-dropdown-list-container
  .available-items {
  height: 190px;
  overflow-y: auto;
  margin-top: 50px;
}

/* .filter_popup .category_select select:focus{
  box-shadow: 0px 0px 10px #afadad !important;
}
.filter_popup .category_select select option{
  padding-bottom: 5px;
} */

.filter_popup label {
  font-size: 18px;
  font-family: "Lato-bold";
  text-transform: uppercase;
  display: block;
  width: 100%;
}

.filter_popup input {
  display: block;
  width: 100%;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 2px solid #ddd8d8;
  color: #414141;
  /* height: 50px; */
  font-size: 16px !important;
  padding-left: 0 !important;
  margin-bottom: 10px !important;
  border-radius: 0 !important;
}

.modal-footer {
  border-top: 0;
}

.filter_popup input:focus {
  outline: none;
  box-shadow: none;
}

.filter_popup .category_select::-ms-expand,
.filter_popup .category_select::-webkit-expand,
.filter_popup .category_select::-moz-expand {
  display: none;
}

.filter_popup .category_select.top {
  margin-top: 10px;
}

.owner_business_name h3 {
  font-family: "Lato-bold";
}

.owner_business_name_mrc h3 a {
  color: var(--theme-mrc-based-primary-color);
}

.owner_business_name_mrc h3 a:hover {
  text-decoration: underline;
}
.owner_business_name_ryc h3 a {
  color: var(--theme-ryc-based-secondary-color);
}

.owner_business_name_ryc h3 a:hover {
  text-decoration: underline;
}

/*country_code dropdown*/

.flag_dropdown .dropbtn {
  background-color: #fff;
  padding: 16px 5px;
  font-size: 24px;
  border-bottom: 1px solid #b2b2b2;
  width: 100%;
  border-left: 0;
  border-top: 0;
  border-right: 0;
  text-align: left;
  position: relative;
}

.flag_dropdown .dropbtn:after {
  position: absolute;
  content: "\f107";
  right: 0;
  color: #000;
  font-family: fontawesome;
}

.flag_dropdown .dropbtn:focus {
  outline: none;
}

.flag_dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.flag_dropdown_list {
  position: absolute;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: 1px solid #000;
  height: 170px;
  overflow-y: auto;
}

.flag_dropdown_list a {
  color: black;
  padding: 5px 15px;
  text-decoration: none;
  display: block;
  font-size: 20px;
}

.flag_dropdown_list a:hover {
  color: #fff;
  background: var(--theme-mrc-based-primary-color);
}

/* D-20-04-2017 Start */

.business_title_icon img {
  width: 25px !important;
  vertical-align: middle !important;
  display: inline-block !important;
  /*margin-left: -4px;*/
  margin-right: 5px;
  height: auto !important;
  margin-right: 5px !important;
}
.business_name.business_title_icon a{display: flex !important; align-items: center ;}
.business_name_align {
  vertical-align: text-top;
  display: inline-block;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
}

.nav_bar_MRC .business_title_icon img {
  vertical-align: middle;
}
.nav_bar_RYC .business_title_icon img {
  vertical-align: middle;
}

.readonly {
  color: #9c9c9c !important;
}

.video_gallery iframe {
  width: 100%;
}

.video_gallery .carousel-item youtube-player,
.video_gallery .carousel-item youtube-player iframe {
  height: 100%;
}

.video_gallery .modal-dialog .modal-content {
  background-color: transparent;
  border: 0;
}

.video_gallery .modal-dialog .modal-header {
  padding: 0;
  border: 0;
}

.video_gallery .modal-dialog .modal-body {
  padding: 0;
}

.business_name a img {
  vertical-align: middle;
}

/* D-20-04-2017 End */

.agree_line {
  text-align: center;
  margin-top: 20px;
}

.agree_line a {
  /* color: #fa8b3f !important; */
  text-decoration: underline !important;
}

.business_content_left h2 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.faq-sec .card-header {
  padding: 0;
  position: relative;
}

.faq-sec .card-header a:before {
  position: absolute;
  content: ">";
  top: 15%;
  right: 13px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  font-size: 25px;
}

.faq-sec .card-header a {
  display: block !important;
  padding: 0.75rem 35px 0.75rem 1.25rem;
  color: #000;
  font-size: 18px;
  position: relative;
}

.faq-sec .card-header.active a:before {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.faq-sec .card-body {
  font-size: 16px;
}

.faq-sec .card-header a:hover,
.faq-sec .card-header.active a {
  background: var(--theme-mrc-based-primary-color);
  color: #fff;
}

.business_type_view {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  min-height: 100px;
  background: rgba(0, 0, 0, 0.7);
}

.business_social_link {
  height: 100%;
}

.business_social_link li {
  height: 100%;
  display: inline-block;
  width: 25%;
  margin: 0 -2px -5px -2px;
  vertical-align: middle;
}

.business_social_link li a {
  display: table;
  height: 100%;
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  font-family: "Lato-Bold";
  vertical-align: middle;
  width: 100%;
}

.business_social_link li a span {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.business_social_link li:first-child a {
  background: var(--theme-mrc-based-primary-color);
  color: #ffffff;
}

.business_social_link li:first-child a i {
  color: #ffffff;
}

.business_social_link li a i {
  display: block;
  line-height: 50px;
  font-size: 40px;
  color: var(--theme-mrc-based-primary-color);
}

.business_social_link li a:hover i {
  color: #fff;
}

/* privacy-policy */

.privacy_policy_view {
  background: #ffffff;
  padding: 40px 20px;
  margin-top: 40px;
}

.privacy_policy_view h4 {
  font-size: 24px;
  border-bottom: 1px solid #ccc;
  /* color: var(--theme-mrc-based-primary-color); */
  font-family: "Lato-Bold";
  margin: 0 0 20px 0;
  padding-bottom: 20px;
}

.privacy_policy_view p {
  color: #424242;
  line-height: 25px;
  text-align: justify;
  margin: 8px 0;
  padding: 5px 0 10px;
  font-size: 14px;
}

.privacy_policy_view ul {
  margin-left: 25px;
}

.required_field {
  color: red;
  font-size: 31px;
  line-height: initial;
  position: relative;
  top: 5px;
  left: 5px;
}

.carousel-indicators {
  display: none !important;
}

/* privacy-policy */

/*slider start*/

.lifetime_slider {
  padding: 20px 0 70px 0;
  text-align: center;
  position: relative;
}

.carousel {
  outline: none;
  box-shadow: none;
}

.lifetime_slider .carousel-control-prev-icon,
.lifetime_slider .carousel-control-next-icon {
  cursor: pointer;
}

.lifetime_slider .member_card .photo {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background: #fff;
  overflow: hidden;
  text-align: center;
  margin: 0 auto;
  display: block;
  border: 2px solid var(--theme-mrc-based-primary-color);
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lifetime_slider .member_card .photo img {
  width: 100%;
  height: 100%;
}

.lifetime_slider .member_card {
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  box-shadow: 0px 0px 16px #e0dfdf;
  border: 2px solid var(--theme-mrc-based-primary-color);
  position: relative;
  padding: 60px 20px 10px 20px;
  max-width: 550px;
}

.lifetime_slider .title {
  margin-bottom: 0 !important;
}

.lifetime_slider .member_card h3 {
  font-size: 21px;
  font-weight: bold;
  color: #fff;
}

.lifetime_slider .member_card h6 {
  color: #fff;
  opacity: 0.7;
}

.lifetime_slider .member_card p {
  font-size: 16px;
  color: #deb04e;
}

.lifetime_slider .carousel-inner {
  padding-top: 90px;
}

.lifetime_slider .carousel-control-prev:hover,
.lifetime_slider .carousel-control-prev:focus,
.lifetime_slider .carousel-control-next:hover,
.lifetime_slider .carousel-control-next:focus {
  outline: none !important;
  text-decoration: none;
  color: transparent;
}

.lifetime_slider:before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  content: "";
  background: #000;
  bottom: 0;
  opacity: 0.7;
}

.lifetime_slider {
  background: url("/assets/images/member.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.testimonial_slider .carousel .carousel-control-prev-icon {
  background-image: url("/assets/images/left_icon.png") !important;
  cursor: pointer;
}

.testimonial_slider .carousel .carousel-control-next-icon {
  background-image: url("/assets/images/right_icon.png") !important;
  cursor: pointer;
}

.testimonial_slider {
  background: url("assets/images/testimonial.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
}

.testimonial_slider:before {
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  opacity: 0.9;
}

.top_menu_section ul li:first-child:after {
  display: none;
}

.market-tab {
  text-align: center;
}

.market_popup .modal-title {
  text-transform: uppercase;
}

.market_popup .market-tab h6 {
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  margin: 30px auto 10px auto;
  border-bottom: 1px solid;
  color: #585858;
}

.market_popup .market-tab p {
  width: 70%;
  color: #585858;
  margin: 0 auto;
  height: 90px;
}

.market_popup .ryec_btn {
  padding: 6px 20% !important;
}

.market_popup .market-tab h3 {
  text-transform: uppercase;
  text-align: center;
  font-size: 24px;
  margin-bottom: 30px;
  font-weight: 900;
}

.memberplan-popup h4 {
  text-transform: uppercase;
}

.memberplan-popup p {
  text-transform: uppercase;
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
}

.memberplan-popup .success-text {
  text-transform: inherit;
}

.memberplan-popup .success-btn button {
  width: 30% !important;
  padding: 5px 0 !important;
}

.memberplan-popup ul {
  margin: 20px;
  list-style-type: disc;
}

.memberplan-popup ul li {
  font-size: 16px;
}

.memberplan-popup .price h4 {
  text-align: center;
  margin-bottom: 20px;
}

.edit_business_form .category_select .ngx-dropdown-button {
  height: 50px !important;
}

span.ad-category {
  font-size: 12px;
  background: #000;
  color: #fff;
  position: absolute;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #fff;
  text-transform: uppercase;
  font-weight: 900;
  margin: 10px;
  left: 20px;
}

.close:hover,
.close:focus {
  outline: none;
}

.our_products_slider .controls {
  margin-top: -25px;
}
.product-main .controls {
  position: absolute;
  width: 145px !important;
  right: 0px;
  top: -50px !important;
  left: inherit !important;
}
.product-main .controls button {
  background-color: #fff !important;
  display: inline-block;
  line-height: 30px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}
.product-main .controls button:hover,
.product-main .controls button:focus {
  background-color: #ff9124;
}
.product-main .controls button:first-child {
  border-radius: 25px 0px 0px 25px;
  width: 65px;
}
.product-main .controls button:last-child {
  border-radius: 0px 25px 25px 0px;
  width: 65px;
}
.product-main .controls button i {
  color: #ff9124 !important;
  font-size: 18px;
}
.product-main .ng2-carouselamos > div {
  border: 1px solid #7f7f7f;
  border-radius: 10px;
  margin: 0px 15px;
}
.product-main .ng2-carouselamos > div .item_name {
  text-align: center;
  padding: 20px 15px;
  font-size: 16px;
  color: #707070;
  opacity: 0.5;
}

.full-width-slider .carousel-control-prev,
.full-width-slider .carousel-control-next {
  width: 50px;
  height: 50px;
  top: 50%;
}

body .orange .rating .progress_bar_cst_mrc .progress-bar, body .orange .rating .progress_bar_cst_ryc .progress-bar {
  background: rgb(255, 137, 35) !important;
  background: linear-gradient(
    90deg,
    rgba(255, 137, 35, 1) 80%,
    rgba(255, 169, 39, 1) 100%
  );
}

body .blue .rating .progress_bar_cst_mrc .progress-bar, body .blue .rating .progress_bar_cst_ryc .progress-bar {
  background-color: #4760ff !important;
}

body .green .rating .progress_bar_cst_mrc .progress-bar, body .green .rating .progress_bar_cst_ryc .progress-bar {
  background-color: #17c788 !important;
}
.enquiry_popup.orange_popup .ryec_btn,
.rating_popup.orange_popup .ryec_btn {
  background-color: #ff8923;
  border: 1px solid #ff8923;
}
.enquiry_popup.orange_popup .ryec_btn:hover,
.rating_popup.orange_popup .ryec_btn:hover {
  background-color: #fff;
  color: #ff8923;
}
.enquiry_popup.blue_popup .ryec_btn,
.rating_popup.blue_popup .ryec_btn {
  background-color: #4760ff;
  border: 1px solid #4760ff;
}
.enquiry_popup.blue_popup .ryec_btn:hover,
.rating_popup.blue_popup .ryec_btn:hover {
  background-color: #fff;
  color: #4760ff;
}
.enquiry_popup.green_popup .ryec_btn,
.rating_popup.green_popup .ryec_btn {
  background-color: #17c788;
  border: 1px solid #17c788;
}
.enquiry_popup.green_popup .ryec_btn:hover,
.rating_popup.green_popup .ryec_btn:hover {
  background-color: #fff;
  color: #17c788;
}
.rating_popup.orange_popup .rate_bar ngb-rating {
  color: #ff8923;
}
.rating_popup.blue_popup .rate_bar ngb-rating {
  color: #4760ff;
}
.rating_popup.green_popup .rate_bar ngb-rating {
  color: #17c788;
}

@media (min-width: 768px) {
  .image_gallery .modal-dialog,
  .service_gallery .modal-dialog,
  .product_gallery .modal-dialog,
  .video_gallery .modal-dialog {
    max-width: 600px;
  }
  .image_gallery .carousel-item,
  .video_gallery .carousel-item {
    height: 400px;
  }
  .product_gallery .img_outer,
  .service_gallery .img_outer {
    height: 500px;
  }
  /* D-20-04-2017 Start */
  .business_title_icon h2 span {
    width: 84%;
    display: inline-block;
    vertical-align: middle;
  }
  .our_products_slider .fa-angle-left {
    /* margin-left: -5px; */
  }
  .business_title_icon img {
    width: 21px;
  }
  .business_social_link li a {
    font-size: 17px;
  }
  .business_social_link li a i {
    font-size: 30px;
  }
}

@media (min-width: 992px) {
  .our_products_slider .fa-angle-left {
    margin-left: 0px;
  }
  .business_title_icon img {
    width: 25px;
  }
  .business_social_link li a {
    font-size: 20px;
  }
  .business_social_link li a i {
    font-size: 40px;
  }
}

@media (min-width: 1024px) {
  .image_gallery .modal-dialog,
  .service_gallery .modal-dialog,
  .product_gallery .modal-dialog,
  .video_gallery .modal-dialog {
    max-width: 800px;
  }
  .image_gallery .carousel-item,
  .video_gallery .carousel-item {
    height: 500px;
  }
  .product_gallery .img_outer,
  .service_gallery .img_outer {
    height: 600px;
  }
  /* D-20-04-2017 Start */
  .business_title_icon h2 span {
    width: 88%;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
  .custom-modal .modal-dialog {
    max-width: 700px;
    border-radius: 10px;
    margin: 11.75rem auto;
  }
  .business_title_icon h2 span {
    width: 91%;
  }
}

@media (max-width: 991px) {
  .category_select .ngx-dropdown-container .ngx-dropdown-button {
    font-size: 16px !important;
  }
}

@media (max-width: 767px) {
  .container {
    max-width: 100%;
  }
  .image_gallery .modal-dialog,
  .service_gallery .modal-dialog,
  .product_gallery .modal-dialog,
  .video_gallery .modal-dialog {
    max-width: 500px;
  }
  .image_gallery .carousel-item,
  .video_gallery .carousel-item {
    height: 400px;
  }
  .product_gallery .img_outer,
  .service_gallery .img_outer {
    height: 400px;
  }
  .product_gallery .description p,
  .service_gallery .description p {
    font-size: 14px;
    line-height: 19px;
  }
  .product_gallery .description h4,
  .service_gallery .description h4 {
    font-size: 18px;
  }
  .product_gallery .description span,
  .service_gallery .description span {
    font-size: 14px;
  }
  .product_gallery .description .content,
  .service_gallery .description .content {
    margin-top: 10px;
  }
  .product_gallery .description,
  .service_gallery .description {
    padding: 0 20px;
    padding-bottom: 10px;
  }
  .enquiry_popup .modal-body input,
  .enquiry_popup .modal-body textarea {
    font-size: 16px;
  }
  .enquiry_popup h5,
  .rating_popup h5 {
    font-size: 18px;
  }
  .rating_popup .modal-body textarea {
    font-size: 16px;
  }
  .rating_popup .modal-body .rate {
    font-size: 16px;
  }
  .mrc_pagination_view ngb-pagination ul li .page-link {
    padding: 5px 15px;
    font-size: 16px;
  }
  .ryc_pagination_view ngb-pagination ul li .page-link {
    padding: 5px 15px;
    font-size: 16px;
  }
  .bd_pagination_view ngb-pagination ul li .page-link {
    padding: 5px 15px;
    font-size: 16px;
  }
  .allcategory_popup ul li {
    font-size: 14px;
    padding: 2px 0;
  }
  .edit_category_popup .category_list ul li {
    font-size: 16px;
    padding: 10px 5px;
  }
  .edit_category_popup .category_selected mat-chip {
    font-size: 12px;
  }
  .edit_category_popup .cat_breadcrumb mat-chip {
    font-size: 12px;
  }
  .edit_category_popup .modal-body h5 {
    font-size: 16px;
  }
  .edit_category_popup .modal-body {
    padding: 10px 15px;
  }
  .flag_dropdown .dropbtn {
    font-size: 20px;
  }
  .business_title_icon h2 span {
    width: 80%;
    display: inline-block;
    vertical-align: middle;
  }
  .business_title_icon img {
    width: 21px !important;
  }
  .faq-sec .card-header a {
    font-size: 16px;
  }
  .faq-sec .card-body {
    font-size: 14px;
  }
  .business_social_link li {
    margin: 0 -2px 0px -2px;
    height: 100px;
  }
  .business_social_link li a {
    font-size: 14px;
  }
  .business_social_link li a i {
    font-size: 30px;
    line-height: 45px;
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 15px;
    height: 15px;
  }
  .lifetime_slider .carousel-control-prev-icon,
  .lifetime_slider .carousel-control-next-icon {
    font-size: 25px;
  }
  .category_select .ngx-dropdown-container .ngx-dropdown-button {
    font-size: 14px !important;
  }
}

@media (max-width: 575px) {
  .flag_dropdown .dropbtn {
    font-size: 16px !important;
  }
  .flag_dropdown_list a {
    font-size: 14px;
  }
  .business_title h2 {
    margin: 20px 0 !important;
  }
  .lifetime_slider .carousel-inner {
    padding-top: 60px;
  }
  .market_popup .market-tab h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

@media (max-width: 560px) {
  .image_gallery .modal-dialog {
    max-width: 500px;
  }
  .image_gallery .carousel-item,
  .video_gallery .carousel-item {
    height: 300px;
  }
  /* .our_products .ng2-carouselamos-wrapper {
        width: 89% !important;
    } */
  .image_gallery .modal-dialog,
  .service_gallery .modal-dialog,
  .product_gallery .modal-dialog,
  .video_gallery .modal-dialog {
    max-width: 350px;
    margin: 0 auto;
  }
  .product_gallery .img_outer,
  .service_gallery .img_outer {
    height: 300px;
  }
  .edit_category_popup .category_dropdown {
    width: 100%;
  }
  .edit_category_popup .category_list {
    height: 300px;
  }
  .edit_category_popup .category_selected mat-chip {
    padding: 5px 30px 5px 15px;
    line-height: 14px;
  }
  .edit_category_popup .category_list ul li button {
    font-size: 14px;
    padding: 0px 10px;
  }
  .modal-header h4 {
    font-size: 18px;
  }
  .modal-body input {
    font-size: 16px !important;
  }
  .filter_popup .price_title h6 {
    font-size: 18px;
  }
  .filter_popup .price_title span {
    font-size: 16px;
  }
  .filter_popup .category_select select {
    font-size: 14px;
  }
  .filter_popup .category_select label {
    font-size: 16px;
  }
  .filter_popup .modal-header h5 {
    font-size: 25px;
  }
  .filter_popup .apply_btn .ryec_btn {
    padding: 10px 20%;
  }
  .owner_business_name h3 {
    font-size: 22px;
  }
  .business_title_icon h2 span {
    width: 90%;
  }
  .our_products_slider .fa-angle-left {
    margin-left: -5px;
  }
  .flag_dropdown_list a {
    padding: 5px;
  }
  .privacy_policy_view {
    padding: 20px;
  }
}

@media (max-width: 460px) {
  .flag_dropdown_list a {
    padding: 5px;
  }
  .business_social_link li {
    margin: 0 -2px 0px -2px;
    height: 70px;
  }
  .business_social_link li a {
    font-size: 14px;
  }
  .business_social_link li a i {
    font-size: 25px;
    line-height: 30px;
  }
}

@media (max-width: 420px) {
  .image_gallery .carousel-item,
  .video_gallery .carousel-item {
    height: 250px;
  }
  /* .our_products .ng2-carouselamos-wrapper {
        width: 70% !important;
    } */
  .mobile_popup .modal-body .profile_btn button {
    font-size: 18px;
  }
  .mrc_pagination_view ngb-pagination ul li .page-link {
    font-size: 14px;
    padding: 5px 8px;
  }
  .ryc_pagination_view ngb-pagination ul li .page-link {
    font-size: 14px;
    padding: 5px 8px;
  }
  .bd_pagination_view ngb-pagination ul li .page-link {
    font-size: 14px;
    padding: 5px 8px;
  }
}

@media (max-width: 360px) {
  .image_gallery .modal-dialog,
  .service_gallery .modal-dialog,
  .product_gallery .modal-dialog,
  .video_gallery .modal-dialog {
    max-width: 300px;
  }
  .image_gallery .carousel-item,
  .video_gallery .carousel-item {
    height: 200px;
  }
  .product_gallery .img_outer,
  .service_gallery .img_outer {
    height: 250px;
  }
  .business_title_icon h2 span {
    width: 85%;
  }
}

body * {
  --orange-theme-color: #ff6b03;
  --color-07-opacity: #ff6b03b3;
  --box-shadow-color: #ff6b034d;
}
body.blue * {
  --orange-theme-color: #4760ff;
  --box-shadow-color: #4760ff4d;
  --color-07-opacity: #4760ffb3;
}
body.green * {
  --orange-theme-color: #17c788;
  --color-07-opacity: #17c788b3;
  --box-shadow-color: #17c7884d;
}
body .blue * {
  --orange-theme-color: #4760ff;
  --box-shadow-color: #4760ff4d;
  --color-07-opacity: #4760ffb3;
}
body .green * {
  --orange-theme-color: #17c788;
  --color-07-opacity: #17c788b3;
  --box-shadow-color: #17c7884d;
}

/* @import "~@angular/material/prebuilt-themes/indigo-pink.css"; */
body * {
  --blue-theme-color: #4760ff;
  --box-shadow-color: #4760ff4d;
  --color-07-opacity: #4760ffb3;
}
body.orange * {
  --blue-theme-color: #ff6b03;
  --color-07-opacity: #ff6b03b3;
  --box-shadow-color: #ff6b034d;
}
body.green * {
  --blue-theme-color: #17c788;
  --color-07-opacity: #17c788b3;
  --box-shadow-color: #17c7884d;
}
body .orange * {
  --blue-theme-color: #ff6b03;
  --color-07-opacity: #ff6b03b3;
  --box-shadow-color: #ff6b034d;
}
body .green * {
  --blue-theme-color: #17c788;
  --color-07-opacity: #17c788b3;
  --box-shadow-color: #17c7884d;
}
.blue-theme .business-detail-banner .carousel-control-prev,
.blue-theme .business-detail-banner .carousel-control-next {
  top: 50%;
  transform: translateY(-50%);
  bottom: auto;
  cursor: pointer;
}
.blue-theme
  .our-product-body
  .ng2-carouselamos-container
  .ng2-carouselamos-wrapper {
  width: 100% !important;
}

.modal-open ngb-modal-window.review-modal input,
.modal-open ngb-modal-window.review-modal textarea {
  border: 1px solid #d9e3ef;
  background: #fcfdff;
  padding: 15px 20px;
  font-size: 16px;
  line-height: 26px;
  color: #72809d;
  display: block;
  width: 100%;
  margin-bottom: 15px;
}
.modal-open ngb-modal-window.review-modal .modal-title {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.6px;
  color: #170f44;
  margin: 0;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
}
.modal-open ngb-modal-window.review-modal .blue-btn,
.modal-open ngb-modal-window.review-modal .green-btn {
  padding: 15px 25px;
  min-width: 145px;
  width: auto;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.48px;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  color: #ffffff;
  background: var(--blue-theme-color);
  -o-box-shadow: 0px 5px 10px var(--box-shadow-color);
  -ms-box-shadow: 0px 5px 10px var(--box-shadow-color);
  -moz-box-shadow: 0px 5px 10px var(--box-shadow-color);
  -webkit-box-shadow: 0px 5px 10px var(--box-shadow-color);
  box-shadow: 0px 5px 10px var(--box-shadow-color);
  padding: 20px;
  display: block;
  text-transform: capitalize;
  cursor: pointer;
  border: 0;
}

ngb-modal-window.review-modal .review-rating .star {
  color: #d3d3d3;
  line-height: 25px;
  font-size: 32px;
  margin-right: 6px;
  margin-bottom: 15px;
}
ngb-modal-window.review-modal .review-rating .filled {
  color: #fdd027;
}
ngb-modal-window.review-modal .review-rating .d-inline-flex:focus {
  outline: none;
}
ngb-modal-window.review-modal .review-body .review-rating {
  margin-bottom: 0;
}
ngb-modal-window.review-modal .review-body .review-rating .star {
  font-size: 20px;
  line-height: 16px;
  margin-right: 3px;
}
/* green theme Css START */
body * {
  --green-theme-color: #17c788;
  --color-07-opacity: #17c788b3;
  --box-shadow-color: #17c7884d;
  --light-green-color: #d6f6eb;
}
body.blue * {
  --green-theme-color: #4760ff;
  --box-shadow-color: #4760ff4d;
  --color-07-opacity: #4760ffb3;
  --light-green-color: #d9deff;
}
body.orange * {
  --green-theme-color: #ff6b03;
  --color-07-opacity: #ff6b03b3;
  --box-shadow-color: #ff6b034d;
  --light-green-color: #ffdec7;
}

body .blue * {
  --green-theme-color: #4760ff;
  --box-shadow-color: #4760ff4d;
  --color-07-opacity: #4760ffb3;
  --light-green-color: #d9deff;
}
body .orange * {
  --green-theme-color: #ff6b03;
  --color-07-opacity: #ff6b03b3;
  --box-shadow-color: #ff6b034d;
  --light-green-color: #ffdec7;
}

.green-theme button:focus {
  box-shadow: none !important;
  outline: none !important;
}
.modal-open ngb-modal-window.review-modal .green-btn {
  background: var(--green-theme-color);
  border-radius: 10px;
}
.green-theme .business-detail-banner .carousel-control-prev,
.green-theme .business-detail-banner .carousel-control-next {
  top: 50%;
  transform: translateY(-50%);
  bottom: auto;
  cursor: pointer;
}
.green-theme .business-detail-banner .carousel-control-prev-icon,
.green-theme .business-detail-banner .carousel-control-next-icon {
  font-family: FontAwesome;
  width: 30px;
  height: 30px;
  background: #f5f5f5;
  border-radius: 50%;
  margin-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 21px;
  background-image: none;
}
.green-theme .business-detail-banner .carousel-control-prev-icon:before {
  content: "\F104";
}
.green-theme .business-detail-banner .carousel-control-next-icon:before {
  content: "\F105";
}

.green-theme
  .our-product-body
  .ng2-carouselamos-container
  .ng2-carouselamos-wrapper {
  width: 100% !important;
}
.green-theme .our-product-body .ng2-carouselamos-container .controls {
  top: -58px;
  right: 0;
  transform: translateY(0);
  left: auto;
  width: auto;
}
.green-theme
  .our-product-body
  .ng2-carouselamos-container
  .product-slider-arrow {
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
}
.green-theme .our-product-body .controls button:hover .product-slider-arrow {
  color: var(--green-theme-color);
  background: var(--box-shadow-color);
}

/* green-theme pagination style */
.green-theme
  .pagination
  ngb-pagination
  ul
  .page-item:first-child
  .page-link
  span,
.green-theme
  .pagination
  ngb-pagination
  ul
  .page-item:last-child
  .page-link
  span {
  display: none !important;
}
.green-theme
  .pagination
  ngb-pagination
  ul
  .page-item:first-child
  .page-link:before {
  content: "Prev" !important;
}
.green-theme
  .pagination
  ngb-pagination
  ul
  .page-item:last-child
  .page-link:before {
  content: "Next" !important;
}
.green-theme .pagination {
  display: flex;
  margin: 0 -5px;
  justify-content: flex-end;
  margin-top: 10px;
}
.green-theme .pagination ngb-pagination .page-item {
  padding: 0 5px;
}
.green-theme .pagination ngb-pagination .page-item .page-link {
  background: #fff;
  color: var(--green-theme-color);
  padding: 15px;
  font-size: 16px;
  line-height: 20px;
  font-family: "Josefin Sans", sans-serif;
  -o-box-shadow: 0px 5px 10px var(--box-shadow-color);
  -moz-box-shadow: 0px 5px 10px var(--box-shadow-color);
  -ms-box-shadow: 0px 5px 10px var(--box-shadow-color);
  -webkit-box-shadow: 0px 5px 10px var(--box-shadow-color);
  box-shadow: 0px 5px 10px var(--box-shadow-color);
  min-width: 54px;
  display: block;
  text-align: center;
  text-transform: capitalize;
  border-radius: 10px;
  border: 0;
  font-family: "Muli", sans-serif;
}
.green-theme .pagination ngb-pagination .page-item .page-link:hover,
.green-theme .pagination ngb-pagination .page-item.active .page-link {
  border-color: var(--green-theme-color);
  background: var(--green-theme-color);
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .green-theme .pagination ngb-pagination .page-item .page-link {
    padding: 8px 11px 9px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 14px;
    min-width: auto;
  }
  .green-theme .pagination ngb-pagination .page-item {
    padding: 0 3px;
  }
}

/* orange theme Css */
.service-main .service-part .ng2-carouselamos-wrapper {
  width: 100% !important;
}
.service-main .service-part .ng2-carouselamos-container {
  position: unset;
}
.service-main .service-part {
  position: relative;
}
.service-main .service-part .ng2-carouselamos-container .controls {
  right: 0;
}
.orange-theme
  .pagination
  ngb-pagination
  ul
  .page-item:first-child
  .page-link
  span,
.orange-theme
  .pagination
  ngb-pagination
  ul
  .page-item:last-child
  .page-link
  span {
  display: none !important;
}
.orange-theme
  .pagination
  ngb-pagination
  ul
  .page-item:first-child
  .page-link:before {
  content: "Prev" !important;
}
.orange-theme
  .pagination
  ngb-pagination
  ul
  .page-item:last-child
  .page-link:before {
  content: "Next" !important;
}
.orange-theme .pagination {
  display: flex;
  margin: 0 -5px;
  justify-content: flex-end;
  margin-top: 10px;
}
.orange-theme .pagination ngb-pagination .page-item {
  padding: 0 5px;
}
.orange-theme .pagination ngb-pagination .page-item .page-link {
  background: #fff;
  color: var(--orange-theme-color);
  padding: 15px;
  font-size: 16px;
  line-height: 20px;
  font-family: "Josefin Sans", sans-serif;
  -o-box-shadow: 0px 5px 10px var(--box-shadow-color);
  -moz-box-shadow: 0px 5px 10px var(--box-shadow-color);
  -ms-box-shadow: 0px 5px 10px var(--box-shadow-color);
  -webkit-box-shadow: 0px 5px 10px var(--box-shadow-color);
  box-shadow: 0px 5px 10px var(--box-shadow-color);
  min-width: 54px;
  display: block;
  text-align: center;
  text-transform: capitalize;
  border-radius: 10px;
  border: 0;
}
.orange-theme .pagination ngb-pagination .page-item .page-link:hover,
.orange-theme .pagination ngb-pagination .page-item.active .page-link {
  border-color: var(--orange-theme-color);
  background: var(--orange-theme-color);
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .orange-theme .pagination ngb-pagination .page-item .page-link {
    padding: 8px 11px 5px;
    font-size: 14px;
    line-height: 14px;
    min-width: auto;
    border-radius: 5px;
  }
  .orange-theme .pagination ngb-pagination .page-item {
    padding: 0 3px;
  }
}

/* blue theme Css */
.blue-theme
  .pagination
  ngb-pagination
  ul
  .page-item:first-child
  .page-link
  span,
.blue-theme
  .pagination
  ngb-pagination
  ul
  .page-item:last-child
  .page-link
  span {
  display: none !important;
}
.blue-theme
  .pagination
  ngb-pagination
  ul
  .page-item:first-child
  .page-link:before {
  content: "Prev" !important;
}
.blue-theme
  .pagination
  ngb-pagination
  ul
  .page-item:last-child
  .page-link:before {
  content: "Next" !important;
}
.blue-theme .pagination {
  display: flex;
  margin: 0 -5px;
  justify-content: flex-end;
  margin-top: 10px;
}
.blue-theme .pagination ngb-pagination .page-item {
  padding: 0 5px;
}
.blue-theme .pagination ngb-pagination .page-item .page-link {
  background: #fff;
  color: var(--blue-theme-color);
  padding: 15px;
  font-size: 16px;
  line-height: 20px;
  font-family: "Josefin Sans", sans-serif;
  -o-box-shadow: 0px 5px 10px var(--box-shadow-color);
  -moz-box-shadow: 0px 5px 10px var(--box-shadow-color);
  -ms-box-shadow: 0px 5px 10px var(--box-shadow-color);
  -webkit-box-shadow: 0px 5px 10px var(--box-shadow-color);
  box-shadow: 0px 5px 10px var(--box-shadow-color);
  min-width: 54px;
  display: block;
  text-align: center;
  text-transform: capitalize;
  border-radius: 0;
  border: 0;
  font-family: "Poppins", sans-serif;
}
.blue-theme .pagination ngb-pagination .page-item .page-link:hover,
.blue-theme .pagination ngb-pagination .page-item.active .page-link {
  border-color: var(--blue-theme-color);
  background: var(--blue-theme-color);
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .blue-theme .pagination ngb-pagination .page-item .page-link {
    padding: 8px 11px 5px;
    font-size: 14px;
    line-height: 14px;
    min-width: auto;
  }
  .blue-theme .pagination ngb-pagination .page-item {
    padding: 0 3px;
  }
}

/* Loader Design */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000000;
}
.overlay .overlayDoor:before,
.overlay .overlayDoor:after {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  background: #111;
  transition: 0.5s cubic-bezier(0.77, 0, 0.18, 1);
  transition-delay: 0.8s;
}
.overlay .overlayDoor:before {
  left: 0;
}
.overlay .overlayDoor:after {
  right: 0;
}
.overlay.loaded .overlayDoor:before {
  left: -50%;
}
.overlay.loaded .overlayDoor:after {
  right: -50%;
}
.overlay.loaded .overlayContent {
  opacity: 0;
  margin-top: -15px;
}
.overlay .overlayContent {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.5s cubic-bezier(0.77, 0, 0.18, 1);
}
.overlay .overlayContent .text {
  display: block;
  width: 130px;
  text-align: center;
  margin: 50px auto 0;
  cursor: pointer;
  color: #fff;
  padding: 12px 0;
  border-radius: 3px;
  transition: 0.2s ease;
}
.loader {
  width: 128px;
  height: 128px;
  border: 3px solid #fff;
  border-bottom: 3px solid transparent;
  border-radius: 50%;
  position: relative;
  animation: spin 1s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader .inner {
  width: 64px;
  height: 64px;
  border: 3px solid transparent;
  border-top: 3px solid #fff;
  border-radius: 50%;
  animation: spinInner 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinInner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-720deg);
  }
}
@media only screen and (max-width: 767px) {
  .loader {
    width: 90px;
    height: 90px;
  }
  .loader .inner {
    width: 44px;
    height: 44px;
  }
  .edit_category_popup .category_selected mat-chip i{top: 5px;}
}
#shortDesc pre{
  overflow: hidden;
  font-size: inherit;
  white-space: normal;
}

:root {
  --theme-mrc-based-primary-color: #fa9d5f; /*For MRC Url*/
  --theme-bd-based-primary-color: #e8653d; /*For MRC Url*/
  --theme-mrc-based-text-primary-color: #000; /*For MRC Url*/
  --theme-bd-based-text-primary-color: #1f3866; 
  --theme-mrc-based-secondary-color: #950012; /*For MRC Url*/
  --theme-ryc-based-primary-color: #ffffff; /*For RYC Url*/
  --theme-ryc-based-text-primary-color: #b6b6b6; /*For RYC Url*/
  --theme-ryc-based-secondary-color: #FF7722; 
  --theme-bd-based-secondary-color: #297745;
}
/* Switch ON-OFF Design */
.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 26px;
}
.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
.switch .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
.switch input:checked + .slider {
  background-color: var(--theme-mrc-based-primary-color);
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px var(--theme-mrc-based-primary-color);
}
.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.switch .slider.round {
  border-radius: 34px;
}
.switch .slider.round:before {
  border-radius: 50%;
}
.required_feild{color: #f00;}

/* Custom upload file CSS */
.upload-area__drop-zoon input[type="file"]{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;
}

.upload-area__drop-zoon {
  position: relative;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 6px;
  cursor: pointer;
  transition: border-color 300ms ease-in-out;
  border: 2px dashed #b2b2b2;
}

.upload-area__drop-zoon:hover {
  border-color: #898989;
}
.upload-area__drop-zoon .remove_btn,.upload-area__drop-zoon .edit_btn {
  position: absolute;
  right: -8px;
  top: -10px;
  width: 23px;
  height: 23px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  line-height: 0;
  padding: 5px;
  z-index: 12;
}
.upload-area__drop-zoon .remove_btn:hover,.upload-area__drop-zoon .edit_btn:hover{ box-shadow: 0px 2px 4px #918484; }
.upload-area__drop-zoon .remove_btn img, .upload-area__drop-zoon .edit_btn img{width: 100%; height: 100%;}
.upload-area__drop-zoon .edit_btn .fa{line-height: 0; font-size: 13px;}
.drop-zoon__icon {
  font-size: 3.75rem;
  color: #898989;
  transition: opacity 300ms ease-in-out;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}
.drop-zoon__icon img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.drop-zoon:hover .drop-zoon__icon,
.drop-zoon:hover .drop-zoon__paragraph {
  opacity: 0.7;
}
@media screen and (max-width: 991px) {
  .upload-area__drop-zoon{height: 120px;}
  .drop-zoon__icon{font-size: 40px;}
}
/* Custom upload file CSS END */