@font-face {
    font-family: 'Lato-Semibold';
    src: url('/assets/fonts/Lato-Semibold.woff2') format('woff2'),
        url('/assets/fonts/Lato-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Lato-Hairline';
    src: url('/assets/fonts/Lato-Hairline.woff2') format('woff2'),
        url('/assets/fonts/Lato-Hairline.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Lato-Black';
    src: url('/assets/fonts/Lato-Black.woff2') format('woff2'),
        url('/assets/fonts/Lato-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Lato-Thin';
    src: url('/assets/fonts/Lato-Thin.woff2') format('woff2'),
        url('/assets/fonts/Lato-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Lato-Light';
    src: url('/assets/fonts/Lato-Light.woff2') format('woff2'),
        url('/assets/fonts/Lato-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Lato-Medium';
    src: url('/assets/fonts/Lato-Medium.woff2') format('woff2'),
        url('/assets/fonts/Lato-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Lato-Bold';
    src: url('/assets/fonts/Lato-Bold.woff2') format('woff2'),
        url('/assets/fonts/Lato-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Lato-Italic';
    src: url('/assets/fonts/Lato-Italic.woff2') format('woff2'),
        url('/assets/fonts/Lato-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Lato-Regular';
    src: url('/assets/fonts/Lato-Regular.woff2') format('woff2'),
        url('/assets/fonts/Lato-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Lato-Heavy';
    src: url('/assets/fonts/Lato-Heavy.woff2') format('woff2'),
        url('/assets/fonts/Lato-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Muli';
    src: url('/assets/fonts/muli/Muli.otf') format('otf'),
        url('/assets/fonts/muli/Muli.ttf') format('ttf'),
        url('/assets/fonts/muli/Muli.woff2') format('woff2'),
        url('/assets/fonts/muli/Muli.eot') format('eot'),
        url('/assets/fonts/muli/Muli.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Muli';
    src: url('/assets/fonts/muli/Muli-Bold.otf') format('otf'),
        url('/assets/fonts/muli/Muli-Bold.ttf') format('ttf'),
        url('/assets/fonts/muli/Muli-Bold.woff2') format('woff2'),
        url('/assets/fonts/muli/Muli-Bold.eot') format('eot'),
        url('/assets/fonts/muli/Muli-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Muli';
    src: url('/assets/fonts/muli/Muli-SemiBold.otf') format('otf'),
        url('/assets/fonts/muli/Muli-SemiBold.ttf') format('ttf'),
        url('/assets/fonts/muli/Muli-SemiBold.woff2') format('woff2'),
        url('/assets/fonts/muli/Muli-SemiBold.eot') format('eot'),
        url('/assets/fonts/muli/Muli-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Muli';
    src: url('/assets/fonts/muli/Muli-Light.otf') format('otf'),
        url('/assets/fonts/muli/Muli-Light.ttf') format('ttf'),
        url('/assets/fonts/muli/Muli-Light.woff2') format('woff2'),
        url('/assets/fonts/muli/Muli-Light.eot') format('eot'),
        url('/assets/fonts/muli/Muli-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}